import type {
	StructuredTextPropTypes,
	StructuredTextGraphQlResponseRecord,
} from 'react-datocms';
import { StructuredText } from 'react-datocms';
import renderInlineRecord from '@datocms/InlineRecord';
import renderLinkToRecord from '@datocms/LinkToRecord';
import globalRules from '@datocms/render-rules';

type T = Omit<
	StructuredTextPropTypes<StructuredTextGraphQlResponseRecord>,
	'renderLinkToRecord' | 'renderInlineRecord'
>;

const MyStructuredText = ({ data, customRules, ...props }: T) => {
	return (
		<StructuredText
			data={data}
			customRules={customRules ? customRules : globalRules}
			renderLinkToRecord={renderLinkToRecord}
			renderInlineRecord={renderInlineRecord}
			{...props}
		/>
	);
};

export default MyStructuredText;
