import type {
	StructuredTextPropTypes,
	StructuredTextGraphQlResponseRecord,
} from 'react-datocms';
import A from '@styled/Link';

type ComponentType =
	StructuredTextPropTypes<StructuredTextGraphQlResponseRecord>['renderLinkToRecord'];

const LinkToRecord: ComponentType = ({ record, children, transformedMeta }) => {
	if (record.__typename === 'HumanRecord') {
		const href =
			record.generatePage && record.slug
				? `/humans/${record.slug}`
				: (record.wikipedia as string) || undefined;
		const TextContainer = href ? A : 'span';
		return (
			<TextContainer {...transformedMeta} href={href}>
				{children}
			</TextContainer>
		);
	}
	if (record.__typename === 'JournalArticleRecord') {
		return (
			<A {...transformedMeta} href={`/journal/${record.slug}`}>
				{children}
			</A>
		);
	}
	if (record.__typename === 'ComposablePageRecord') {
		return (
			<A {...transformedMeta} href={`/${record.slug}`}>
				{children}
			</A>
		);
	}
	if (record.__typename === 'JournalIndexRecord') {
		return (
			<A {...transformedMeta} href={`/journal`}>
				{children}
			</A>
		);
	}
	if (record.__typename === 'HomePageRecord') {
		return (
			<A {...transformedMeta} href={`/`}>
				{children}
			</A>
		);
	}
	return <></>;
};

export default LinkToRecord;
