/* This example requires Tailwind CSS v2.0+ */
import {
	WifiIcon,
	ExclamationCircleIcon,
	LockClosedIcon,
	RefreshIcon,
} from '@heroicons/react/outline';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useToggle } from 'react-use';
import tw, { styled } from 'twin.macro';
import Heroicon from 'components/Heroicon';
import { boolVariant } from 'stitches.config';

const environment = process.env.NEXT_PUBLIC_DATOCMS_ENVIRONMENT || '';

const StyledShowHideButton = styled('button', {
	...tw`inline-flex items-center p-3 border border-transparent`,
	...tw`rounded-full shadow-sm focus:(outline-none ring-2 ring-offset-2)`,
	variants: {
		stateDisplay: {
			default: tw`text-sky-700 bg-white`,
			hidden: tw`bg-sky-700 text-white`,
			error: tw`text-red-600 bg-white`,
			hiddenError: tw`bg-red-600 text-white`,
		},
	},
});
const ShowHideButton = ({ isHidden, toggle, error }) => {
	const stateDisplay = isHidden
		? error
			? 'hiddenError'
			: 'hidden'
		: error
		? 'error'
		: 'default';
	return (
		<StyledShowHideButton
			type="button"
			tabIndex={-1}
			onClick={toggle}
			stateDisplay={stateDisplay}>
			<span tw="sr-only">{`${
				isHidden ? 'show' : 'hide'
			} DatoCMS live preview banner`}</span>
			<Heroicon
				heroicon={isHidden ? 'eye' : 'chevron-down'}
				tw="h-6 w-6"
				aria-hidden="true"
			/>
		</StyledShowHideButton>
	);
};

const StyledExitPreviewButton = styled('a', {
	...tw`inline-flex bg-white items-center p-3 border border-transparent`,
	...tw`rounded-full shadow-sm focus:(outline-none ring-2 ring-offset-2)`,
	variants: {
		...boolVariant('hasError', tw`text-red-600`, tw`text-sky-700`),
	},
});
const ExitPreviewButton = ({ exitPath, error }) => {
	return (
		<StyledExitPreviewButton
			type="button"
			href={`/api/exit-preview/?slug=${exitPath}`}
			tabIndex={-1}
			hasError={!!error}>
			<span tw="sr-only">exit DatoCMS live preview`</span>
			<Heroicon heroicon={'eye-off'} tw="h-6 w-6" aria-hidden="true" />
		</StyledExitPreviewButton>
	);
};

const statusMessage = {
	connecting: 'Connecting to DatoCMS preview endpoint... ',
	connected:
		'Connected to DatoCMS, receiving live updates from preview endpoint!',
	closed: 'Connection with DatoCMS preview endpoint closed.',
};

export default function QuerySubscriptionBanner({ error, status }) {
	const { pathname, locale, defaultLocale } = useRouter();

	const [exitPath, setExitPath] = useState(
		`/${locale !== defaultLocale ? locale : ''}${pathname}`
	);

	const [isHidden, toggle] = useToggle(false);
	useEffect(() => {
		setExitPath(window.location.pathname);
	}, [pathname, locale]);

	const iconStyle = !error ? tw`bg-sky-900` : tw`bg-red-800`;

	return (
		<div tw="fixed bottom-0 z-50" css={isHidden ? tw`right-0` : tw`inset-x-0 `}>
			{!isHidden ? (
				<div css={!error ? tw`bg-sky-700` : tw`bg-red-600`}>
					<div tw="py-3 px-3 sm:px-6 lg:px-8">
						<div tw="flex items-center justify-between flex-wrap">
							<div tw="w-0 flex-1 flex items-center">
								{!error && status === 'connecting' && (
									<span tw="flex p-2 rounded-lg" css={iconStyle}>
										<RefreshIcon tw="h-6 w-6 text-white" aria-hidden="true" />
									</span>
								)}
								{!error && status === 'connected' && (
									<span tw="flex p-2 rounded-lg" css={iconStyle}>
										<WifiIcon tw="h-6 w-6 text-white" aria-hidden="true" />
									</span>
								)}
								{!error && status === 'closed' && (
									<span tw="flex p-2 rounded-lg" css={iconStyle}>
										<LockClosedIcon
											tw="h-6 w-6 text-white"
											aria-hidden="true"
										/>
									</span>
								)}
								{!!error && (
									<span tw="flex p-2 rounded-lg" css={iconStyle}>
										<ExclamationCircleIcon
											tw="h-6 w-6 text-white"
											aria-hidden="true"
										/>
									</span>
								)}
								<p tw="ml-3 font-medium text-white truncate">
									{!error && (
										<span>{`${statusMessage[status]} ${
											environment && `[${environment}]`
										}`}</span>
									)}
									{error && (
										<span>DatoCMS Connection Error: {error.message}</span>
									)}
								</p>
							</div>
							<div tw="space-x-7">
								<ExitPreviewButton error={error} exitPath={exitPath} />
								<ShowHideButton
									error={error}
									isHidden={isHidden}
									toggle={toggle}
								/>
							</div>
						</div>
					</div>
				</div>
			) : (
				<div tw="py-3 px-3 sm:px-6 lg:px-8">
					<ShowHideButton error={error} isHidden={isHidden} toggle={toggle} />
				</div>
			)}
		</div>
	);
}
