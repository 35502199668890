import Layout from '@layouts/index';
import DefaultErrorPage from 'next/error';
import usePageContext from 'hooks/usePageContext';
import LivePreview from './LivePreview';

const PreviewLayout = ({ data, error, status, children }) => {
	const { availableLocales, page, index, layout, isPreview } =
		usePageContext(data);

	const pageContext = page ?? index;
	if (isPreview) {
		return (
			<>
				<LivePreview status={status} error={error} />
				{!!pageContext && !!layout && (
					<Layout layout={layout} availableLocales={availableLocales}>
						{children}
					</Layout>
				)}
				{!!pageContext && !layout && <>{children}</>}
				{!pageContext && (
					<DefaultErrorPage
						statusCode={404}
						title={'You deleted the preview version of the page in DatoCMS'}
					/>
				)}
			</>
		);
	}
	return <>{children}</>;
};

export default PreviewLayout;
