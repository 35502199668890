import type {
	StructuredTextPropTypes,
	StructuredTextGraphQlResponseRecord,
} from 'react-datocms';
import A from '@styled/Link';
import 'twin.macro';

type ComponentType =
	StructuredTextPropTypes<StructuredTextGraphQlResponseRecord>['renderInlineRecord'];

const InlineRecord: ComponentType = ({ record: { __typename, ...data } }) => {
	if (__typename === 'HumanRecord') {
		const { generatePage, slug, wikipedia, name } = data as any;
		if (generatePage && slug) {
			return <A href={`/humans/${slug}`}><em>{name}</em></A>;
		}
		if (wikipedia) {
			return (
				<A href={wikipedia}>
					<em>{name}</em>
				</A>
			);
		}
		return (
			<strong>
				<em>{name}</em>
			</strong>
		);
	}
	if (__typename === 'JournalArticleRecord') {
		const { slug, title } = data as any;
		return <A href={`/journal/${slug}`}>{title}</A>;
	}
	if (__typename === 'ComposablePageRecord') {
		const { slug, title } = data as any;
		return <A href={`/${slug}`}>{title}</A>;
	}
	if (__typename === 'JournalIndexRecord') {
		const { title } = data as any;
		return <A href={`/journal`}>{title || 'Journal'}</A>;
	}
	if (__typename === 'HomePageRecord') {
		const { heroSection } = data as any;
		return <A href={`/`}>{heroSection?.[0]?.title || 'Homepage'}</A>;
	}
	return <></>;
};

export default InlineRecord;
